<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { useRouter } from "vue-router";
import { getAppTypes } from "../../../services/case";

export default {
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const property = ref("");
    const types = ref("");

    onMounted(async () => {
      try {
        const { data } = await getAppTypes();
        types.value = data;
      } catch (e) {
        console.log(e);
      }
    });

    const handleSubmit = () => {
      store.dispatch("cases/updateProperty", {
        type: property.value,
        types: types.value,
      });
      router.push({ name: 2 });
    };

    return {
      types,
      property,
      handleSubmit,
    };
  },
};
</script>

<template>
  <div class="grid lg:gap-y-12 md:gap-y-8 gap-y-4">
    <div>
      <h2 class="text-3xl font-extrabold text-gray-900 text-center">
        Type of application
      </h2>
      <p class="mt-2 text-sm text-blue-700 font-medium text-center">
        To formally instruct us and begin the legal process we’re going <br />
        to need a few additional details from you. Please complete the form
        below.
      </p>
    </div>
    <form @submit.prevent="handleSubmit" class="max-w-sm m-auto space-y-4">
      <RadioGroup v-model="property">
        <RadioGroupLabel class="sr-only">Property Type</RadioGroupLabel>
        <div class="bg-white rounded-md -space-y-px relative">
          <input required v-model="property" class="sr-only left-1/2 top-1/2" />
          <RadioGroupOption
            as="template"
            v-for="(type, i) in types"
            :key="type.name"
            :value="type.uid"
            v-slot="{ checked, active }"
          >
            <div
              :class="[
                i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                i === types.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none',
              ]"
            >
              <span
                :class="[
                  checked
                    ? 'bg-blue-600 border-transparent'
                    : 'bg-white border-gray-300',
                  active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                ]"
                aria-hidden="true"
              >
                <span class="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <div class="ml-3 flex flex-col">
                <RadioGroupLabel
                  as="span"
                  :class="[
                    checked ? 'text-blue-900' : 'text-gray-900',
                    'block text-sm font-medium',
                  ]"
                >
                  {{ type.name }}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  :class="[
                    checked ? 'text-blue-700' : 'text-gray-500',
                    'block text-sm',
                  ]"
                >
                  {{ type.description }}
                </RadioGroupDescription>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <button
        type="submit"
        class="
          inline-flex
          float-right
          w-full
          justify-center
          items-center
          px-4
          py-2
          border border-transparent
          text-base
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-light-blue-700
          hover:bg-light-blue-800
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:light-blue-500
        "
      >
        Save
      </button>
    </form>
  </div>
</template>
